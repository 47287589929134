<template>
  <div class="super-admin-login">
    Login...
  </div>
</template>

<script>
import JWTDecode from "jwt-decode";
import {mapActions} from "vuex";

/**
 * 超管登陆认证
 */
export default {
  name: "Admin",
  created () {
    const token = this.$route.query.token;
    try {
      const userInfo = JWTDecode(token);
      userInfo.token = token;
      this.setUserInfo(userInfo);
      this.$router.replace("/");
    } catch (e) {
      this.$message.error("token信息有误");
      setTimeout(() => this.$router.replace("/login"), 1000);
      console.error(e);
    }
  },
  methods: {
    ...mapActions({
      setUserInfo: "user/setUserInfo",
    })
  }
};
</script>

<style scoped>
.super-admin-login {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
}
</style>
